import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { m } from 'framer-motion';
import { varContainer } from './variants';
export default function MotionContainer({ animate, action = false, children, ...other }) {
    if (action) {
        return (_jsx(Box, { component: m.div, initial: false, animate: animate ? 'animate' : 'exit', variants: varContainer(), ...other, children: children }));
    }
    return (_jsx(Box, { component: m.div, initial: "initial", animate: "animate", exit: "exit", variants: varContainer(), ...other, children: children }));
}
